import Controller from '../base';
import { state, DEFAULT_LINK_HREF } from '../state';
import t from '../translate';

// error_description -> translation key mapping:
const description = {
  'The transaction has expired': t('error-page-two-factor-code-expired-error'),
  'user is blocked, please change your password': t('error-page-blocked-user-error'),
  'user is blocked': t('error-page-blocked-user-error2')
}[state.get('error_description')] || state.get('error_description') || '';

export default class extends Controller {
  static targets = ['image'];

  onLinkClick() {
    window.location.href = DEFAULT_LINK_HREF();
  }

  connect() {
    this.render({
      description,
      showButtons: !['Sonos'].includes(state.get('app')),
    });
  }
}
