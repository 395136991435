{
  "success-page-sub-logo-text": "一個 {{company_name}} 帳戶，適用於您的所有應用程式。",
  "success-page-text": "返回應用程式，以繼續進行設定。",

  "return-to-app-button-text": "返回 {{app}}",

  "error-page-title": "糟糕！",
  "error-page-text": "連線至您的帳戶時遇到問題。",
  "error-page-two-factor-code-expired-error": "雙因素驗證代碼過期",
  "error-page-need-help-text": "需要協助嗎？ <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">訪問線上說明網站</a>",
  "error-page-try-again-button": "重試",
  "error-page-blocked-user-error": "為保護您的資料，對您帳戶的存取權限已被停用。要重新存取您的帳戶，您必須重設密碼。我們建議您選擇一個以前從未使用過的，安全性較高的唯一密碼。",
  "error-page-blocked-user-error2": "對您帳戶的存取權限已被停用。",
  "error-user-exists": "此電子郵件地址已使用",

  "back": "返回",

  "inline-error": "出現問題。重試。",

  "footer-1": "一個 {{company_name}} 帳戶，適用於您的所有應用程式。",
  "footer-2": "© 2022 {{company_name}} Corporation 或其關係企業。版權所有。",

  "verify-email-title": "驗證您的電子郵件",

  "verify-email-text-1": "已建立您的帳戶。為保護您的 {{company_name}} 帳戶，您必須驗證您的電子郵件地址。檢查從 {{company_name}} 傳送至 <b>{{email}}</b> 的電子郵件。按照電子郵件中的說明操作後，回到此處按一下<b>檢視驗證狀態</b>。",
  "verify-email-text-2": "如您需要，您可以<b><a href=\"#\" data-action=\"verify-email#changeEmail\">變更電子郵件地址</a></b>以便用於自己的 {{company_name}} 帳戶。",

  "verify-email-button-1.1": "檢視驗證狀態",
  "verify-email-button-1.2": "驗證...",
  "verify-email-button-1.3": "電子郵件未驗證",

  "verify-email-button-2.1": "重新傳送電子郵件",
  "verify-email-button-2.2": "重新傳送...",
  "verify-email-button-2.3": "電子郵件已傳送",

  "verify-email-verification-error-message": "您的電子郵件尚未驗證。",

  "verify-email-toast": "請耐心等候，電子郵件可能需要幾分鐘才能出現在您的收件匣中。",

  "change-email-title": "變更電子郵件",
  "change-email-text": "輸入您的電子郵件地址，以便我們向您傳送電子郵件以進行驗證。",
  "change-email-placeholder": "新電子郵件",
  "change-email-button-1.1": "變更電子郵件",
  "change-email-button-1.2": "變更...",

  "reset-password-title": "重設密碼",
  "reset-password-text": "請輸入您的電子郵件地址，我們將會傳送指示給您以便重設您的密碼。",
  "reset-password-placeholder": "電子郵件地址",
  "reset-password-button-text": "繼續",
  "reset-password-success": "請查看電子郵件地址：{{email}}，<br/> 以取得重設您密碼的指示。",
  "reset-password-retry": "重新傳送電子郵件"
}
