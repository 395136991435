import { customFetch } from './wrapper';

const base = 'service.account.login.url';

export const getTokenByCode = ({ code, clientId }) => {
  const path = '/oauth/token';

  return customFetch(base, path, {
    method: 'POST',
    body: JSON.stringify({
      grant_type: 'authorization_code',
      code,

      // use current URL as a redirect URL
      redirect_uri: window.location.origin,
      audience: 'mycloud.com',
      scope: 'openid offline_access',
      client_id: clientId,
    }),
  });
};

export const getUserinfo = ({ accessToken }) => {
  const path = `/userinfo?access_token=${accessToken}`;

  return customFetch(base, path, {
    method: 'GET',
  });
};

export const resetPassword = ({ email, clientId }) => {
  const path = '/dbconnections/change_password';

  return customFetch(base, path, {
    method: 'POST',
    body: JSON.stringify({
      email,
      client_id: clientId,
      connection: 'Username-Password-Authentication',
    }),
    ignoreResponseBody: true,
  });
};
