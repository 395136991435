{
  "success-page-sub-logo-text": "Ett {{company_name}}-konto för dina appar.",
  "success-page-text": "Återvänd till applikationen för att fortsätta installeringen.",

  "return-to-app-button-text": "Återgå till {{app}}",

  "error-page-title": "Hoppsan!",
  "error-page-text": "Vi stötte på ett problem när vi anslöt till ditt konto.",
  "error-page-two-factor-code-expired-error": "Koden för tvåfaktorsautentisering har gått ut",
  "error-page-need-help-text": "Behöver du hjälp? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Besök vår hjälpsida online</a>",
  "error-page-try-again-button": "Försök igen",
  "error-page-blocked-user-error": "För att skydda din data har åtkomst till ditt konto inaktiverats. För att återfå åtkomst till ditt konto måste du återställa ditt lösenord. Vi föreslår att du väljer ett starkt, unikt lösenord som du aldrig har använt tidigare.",
  "error-page-blocked-user-error2": "Åtkomsten till ditt konto har inaktiverats.",
  "error-user-exists": "Denna e-postadress är upptagen",

  "back": "Tillbaka",

  "inline-error": "Något gick fel. Försök igen.",

  "footer-1": "Ett {{company_name}}-konto för dina appar.",
  "footer-2": "© 2022 {{company_name}} Corporation eller dess dotterbolag. Alla rättigheter förbehålles.",

  "verify-email-title": "Verifiera din e-postadress",

  "verify-email-text-1": "Ditt konto har skapats. För att skydda ditt {{company_name}}-konto bättre behöver vi verifiera din e-postadress. Sök efter en e-post som skickats från {{company_name}} till <b>{{email}}</b>. Följ instruktionerna i e-postmeddelandet gå sedan tillbaka hit för att klicka på <b>Kontrollera verifieringsstatus</b>.",
  "verify-email-text-2": "Om du behöver kan du <b><a href=\"#\" data-action=\"verify-email#changeEmail\">ändra e-postadressen</a></b> som du använder för ditt {{company_name}}-konto.",

  "verify-email-button-1.1": "Kontrollera verifieringsstatus",
  "verify-email-button-1.2": "Verifierar ...",
  "verify-email-button-1.3": "E-post ej verifierad",

  "verify-email-button-2.1": "Skicka e-post igen",
  "verify-email-button-2.2": "Skickar igen ...",
  "verify-email-button-2.3": "Email har skickats",

  "verify-email-verification-error-message": "Din e-postadress är ännu inte verifierad.",

  "verify-email-toast": "Ha tålamod, det kan ta några minuter innan e-postmeddelandet visas i din inkorg.",

  "change-email-title": "Ändra e-post",
  "change-email-text": "Ange din e-postadress så att vi kan skicka ett e-postmeddelande för att verifiera.",
  "change-email-placeholder": "Ny e-post",
  "change-email-button-1.1": "Ändra e-post",
  "change-email-button-1.2": "Ändrar ...",

  "reset-password-title": "Återställ lösenord",
  "reset-password-text": "Ange din e-postadress för att få <br/> instruktioner om hur du återställer ditt lösenord.",
  "reset-password-placeholder": "E-postadress",
  "reset-password-button-text": "Fortsätt",
  "reset-password-success": "Kontrollera e-postadressen {{email}} <br/> där du har fått instruktioner om <br/> hur du återställer ditt lösenord.",
  "reset-password-retry": "Skicka e-postmeddelandet igen"
}
