import Cookies from 'js-cookie';

import { AUTHORIZE_LINK_HREF, state } from './state';
import { COOKIES_BASED_SKIP_VERIFICATION } from './features';

export const authorize = ({ skipVerification }) => {
  if (
    COOKIES_BASED_SKIP_VERIFICATION
    && skipVerification
  ) {
    Cookies.set('skipVerification', state.get('state'));
  }

  window.location.replace(AUTHORIZE_LINK_HREF({ skipVerification }));
};
