import { Controller } from '@hotwired/stimulus';
import template, { go } from './template';
import t from './translate';

export default class extends Controller {
  get APP() {
    return {
      ROUTES: this.constructor.ROUTES,
      go,
    };
  }

  get TEMPLATE_NAME() {
    return this.constructor.TEMPLATE;
  }

  get template() {
    return template(this.TEMPLATE_NAME);
  }

  render = (args) => {
    this.element.innerHTML = this.template(args);
  };

  initialize() {
    const title = document.querySelector('title');
    title.innerHTML = this.title || t('return-to-app-button-text');
  }

  connect() {
    this.render();
  }
}
