{
  "success-page-sub-logo-text": "One {{company_name}} Account for your apps.",
  "success-page-text": "Return to the application to continue setup.",

  "return-to-app-button-text": "Return to {{app}}",

  "error-page-title": "Whoops!",
  "error-page-text": "We encountered an issue connecting to your account.",
  "error-page-two-factor-code-expired-error": "Two-factor authentication code expired",
  "error-page-need-help-text": "Need help? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Visit our online help site</a>",
  "error-page-try-again-button": "Try again",
  "error-page-blocked-user-error": "To protect your data, access to your account has been disabled. To regain access to your account, you must reset your password. We suggest that you select a strong, unique password that you've never used before.",
  "error-page-blocked-user-error2": "Access to your account has been disabled.",
  "error-user-exists": "This email address is already used",

  "back": "Back",

  "inline-error": "Something went wrong. Try again.",

  "footer-1": "One {{company_name}} Account for your apps.",
  "footer-2": "© 2022 {{company_name}} Corporation or its affiliates. All rights reserved.",

  "verify-email-title": "Verify your email",

  "verify-email-text-1": "Your account is created. To better protect your {{company_name}} Account, you must verify your email address. Check for an email sent from {{company_name}} to <b>{{email}}</b>. Follow the instructions in the email then return here to click <b>Check verification status</b>.",
  "verify-email-text-2": "If you need to, you can <b><a href=\"#\" data-action=\"verify-email#changeEmail\">change the email address</a></b> you use for your {{company_name}} account.",

  "verify-email-button-1.1": "Check verification status",
  "verify-email-button-1.2": "Verifying...",
  "verify-email-button-1.3": "Email not verified",

  "verify-email-button-2.1": "Resend email",
  "verify-email-button-2.2": "Resending...",
  "verify-email-button-2.3": "Email sent",

  "verify-email-verification-error-message": "Your email address isn't verified yet.",

  "verify-email-toast": "Be patient, it might take a few minutes for the email to appear in your inbox.",

  "change-email-title": "Change email",
  "change-email-text": "Enter your email address so that we can send you an email to verify.",
  "change-email-placeholder": "New email",
  "change-email-button-1.1": "Change email",
  "change-email-button-1.2": "Changing...",

  "reset-password-title": "Reset password",
  "reset-password-text": "Enter your email address and we will send <br/> you instructions to reset your password.",
  "reset-password-placeholder": "Email address",
  "reset-password-button-text": "Continue",
  "reset-password-success": "Please check the email address {{email}} <br/> for instructions to reset your password.",
  "reset-password-retry": "Resend email"
}
