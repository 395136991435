{
  "success-page-sub-logo-text": "Jeden účet {{company_name}} pre všetky vaše aplikácie.",
  "success-page-text": "Ak chcete pokračovať s nastavením, vráťte sa do aplikácie.",

  "return-to-app-button-text": "Vrátiť sa do aplikácie {{app}}",

  "error-page-title": "Ojoj!",
  "error-page-text": "Vyskytol sa problém s pripojením k vášmu účtu.",
  "error-page-two-factor-code-expired-error": "Vypršala platnosť kódu pre dvojúrovňové overovanie",
  "error-page-need-help-text": "Potrebujete pomoc? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Navštívte náš web pomoci online</a>",
  "error-page-try-again-button": "Skúste to znova",
  "error-page-blocked-user-error": "Prístup do vášho účtu sme deaktivovali, aby sme ochránili vaše údaje. Ak chcete do svojho účtu znova získať prístup, musíte resetovať heslo. Odporúčame zvoliť silné, jedinečné heslo, ktoré ste ešte nikdy nepoužívali.",
  "error-page-blocked-user-error2": "Prístup do vášho účtu bol zakázaný.",
  "error-user-exists": "Táto e-mailová adresa sa už používa",

  "back": "Späť",

  "inline-error": "Vyskytol sa problém. Skúste to znova.",

  "footer-1": "Jeden účet {{company_name}} pre všetky vaše aplikácie.",
  "footer-2": "© 2022 {{company_name}} Corporation a jej pridružené spoločnosti. Všetky práva vyhradené.",

  "verify-email-title": "Overte svoj e-mail",

  "verify-email-text-1": "Váš účet bol vytvorený. Z dôvodu lepšej ochrany vášho účtu {{company_name}} musíme overiť vašu e-mailovú adresu. Skontrolujte e-maily odoslané spoločnosťou {{company_name}} na e-mail <b>{{email}}</b>. Podľa pokynov v e-maili sa vráťte sem a kliknite na možnosť <b>Skontrolovať stav overenia</b>.",
  "verify-email-text-2": "V prípade potreby môžete <b><a href=\"#\" data-action=\"verify-email#changeEmail\">zmeniť e-mailovú adresu</a></b>, ktorú používate s účtom {{company_name}}.",

  "verify-email-button-1.1": "Skontrolovať stav overenia",
  "verify-email-button-1.2": "Overuje sa…",
  "verify-email-button-1.3": "E-mail nebol overený",

  "verify-email-button-2.1": "Odoslať e-mail znova",
  "verify-email-button-2.2": "Odosiela sa znova…",
  "verify-email-button-2.3": "E-mail bol odoslaný",

  "verify-email-verification-error-message": "Vaša e-mailová adresa ešte nie je overená.",

  "verify-email-toast": "Buďte trpezliví, e-mail sa môže objaviť vo vašej pošte až za niekoľko minút.",

  "change-email-title": "Zmeniť e-mail",
  "change-email-text": "Zadajte e-mailovú adresu, na ktorú vám pošleme overovací e-mail.",
  "change-email-placeholder": "Nový e-mail",
  "change-email-button-1.1": "Zmeniť e-mail",
  "change-email-button-1.2": "Mení sa…",

  "reset-password-title": "Obnoviť heslo",
  "reset-password-text": "Zadajte e-mailovú adresu, <br/> aby sme vám mohli poslať pokyny <br/> na obnovenie hesla.",
  "reset-password-placeholder": "E-mailová adresa",
  "reset-password-button-text": "Pokračovať",
  "reset-password-success": "Skontrolujte e-mailovú adresu <br/> {{email}}, kde nájdete pokyny na <br/> resetovanie hesla.",
  "reset-password-retry": "Opätovne zaslať e-mail"
}
