{
  "success-page-sub-logo-text": "Eén {{company_name}}-account voor je apps.",
  "success-page-text": "Ga terug naar de toepassing om door te gaan met instellen.",

  "return-to-app-button-text": "Ga terug naar {{app}}",

  "error-page-title": "Oeps!",
  "error-page-text": "We zijn een probleem tegengekomen bij het maken van verbinding met je account.",
  "error-page-two-factor-code-expired-error": "De code voor tweeledige verificatie is verlopen",
  "error-page-need-help-text": "Hulp nodig? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Bezoek onze online Help-site</a>",
  "error-page-try-again-button": "Probeer het opnieuw",
  "error-page-blocked-user-error": "Ter bescherming van je gegevens is de toegang tot je account uitgeschakeld. Als je opnieuw toegang tot je account wilt krijgen, moet je je wachtwoord opnieuw instellen. We stellen voor dat je een sterk, uniek wachtwoord selecteert dat je nooit eerder hebt gebruikt.",
  "error-page-blocked-user-error2": "De toegang tot je account is uitgeschakeld.",
  "error-user-exists": "Dit e-mailadres wordt al gebruikt",

  "back": "Terug",

  "inline-error": "Er is iets misgegaan. Probeer het opnieuw.",

  "footer-1": "Eén {{company_name}}-account voor je apps.",
  "footer-2": "© 2022 {{company_name}} Corporation of haar dochterondernemingen. Alle rechten voorbehouden.",

  "verify-email-title": "Je e-mail verifiëren",

  "verify-email-text-1": "Je account wordt gemaakt. Om je {{company_name}}-account beter te kunnen beschermen, moeten we je e-mailadres verifiëren. Controleer op een e-mail die door {{company_name}} is verzenden naar <b>{{email}}</b>. Volg de instructies in de e-mail en keer vervolgens hier terug en klik op <b>Verificatiestatus controleren</b>.",
  "verify-email-text-2": "Indien nodig, kun je <b><a href=\"#\" data-action=\"verify-email#changeEmail\">het e-mailadres wijzigen</a></b> dat je gebruikt voor je {{company_name}}-account.",

  "verify-email-button-1.1": "Verificatiestatus controleren",
  "verify-email-button-1.2": "Bezig met verifiëren...",
  "verify-email-button-1.3": "E-mail niet geverifieerd",

  "verify-email-button-2.1": "E-mail opnieuw verzenden",
  "verify-email-button-2.2": "Bezig met opnieuw verzenden...",
  "verify-email-button-2.3": "E-mail verzonden",

  "verify-email-verification-error-message": "Je e-mailadres is nog niet geverifieerd.",

  "verify-email-toast": "Een ogenblik geduld. Het kan enkele minuten duren voordat de e-mail in je postvak verschijnt.",

  "change-email-title": "E-mail wijzigen ",
  "change-email-text": "Voer je e-mailadres in zodat we je een e-mail kunnen sturen ter verificatie.",
  "change-email-placeholder": "Nieuwe e-mail",
  "change-email-button-1.1": "E-mail wijzigen ",
  "change-email-button-1.2": "Bezig met wijzigen…",

  "reset-password-title": "Wachtwoord opnieuw instellen",
  "reset-password-text": "Voer uw e-mailadres in. Dan sturen we u <br/> instructies om uw wachtwoord opnieuw <br/> in te stellen.",
  "reset-password-placeholder": "E-mailadres",
  "reset-password-button-text": "Doorgaan",
  "reset-password-success": "Controleer het e-mailadres {{email}} <br/> voor instructies om uw wachtwoord <br/> opnieuw in te stellen.",
  "reset-password-retry": "E-mail opnieuw verzenden"
}
