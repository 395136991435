{
  "success-page-sub-logo-text": "Una cuenta de {{company_name}} para tus aplicaciones.",
  "success-page-text": "Volver a la aplicación para continuar con la configuración.",

  "return-to-app-button-text": "Volver a {{app}}",

  "error-page-title": "¡Vaya!",
  "error-page-text": "Hemos tenido un problema al intentar conectar con tu cuenta.",
  "error-page-two-factor-code-expired-error": "El código de la autenticación de dos factores ha caducado",
  "error-page-need-help-text": "¿Necesitas ayuda? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Visita la página de ayuda online.</a>",
  "error-page-try-again-button": "Inténtalo de nuevo",
  "error-page-blocked-user-error": "Se ha deshabilitado el acceso a la cuenta para proteger tus datos. Restablece tu contraseña para acceder de nuevo a tu cuenta. Te sugerimos que elijas una contraseña segura y única que nunca hayas usado antes.",
  "error-page-blocked-user-error2": "Se ha deshabilitado el acceso a tu cuenta.",
  "error-user-exists": "Esta dirección de correo electrónico ya está en uso",

  "back": "Volver",

  "inline-error": "Algo ha fallado. Inténtalo de nuevo.",

  "footer-1": "Una cuenta de {{company_name}} para tus aplicaciones.",
  "footer-2": "© 2022 {{company_name}} Corporation o sus filiales. Todos los derechos reservados.",

  "verify-email-title": "Verificar tu correo electrónico",

  "verify-email-text-1": "Se ha creado tu cuenta. Para proteger mejor tu cuenta de {{company_name}}, debemos verificar tu dirección de correo electrónico. Comprueba si has recibido un correo electrónico de {{company_name}} enviado a <b>{{email}}</b>. Sigue las instrucciones del correo electrónico y después vuelve aquí para hacer clic en <b>Comprobar estado de verificación</b>.",
  "verify-email-text-2": "Si lo necesitas, puedes <b><a href=\"#\" data-action=\"verify-email#changeEmail\">cambiar la dirección de correo electrónico</a></b> que usas para tu cuenta de {{company_name}}.",

  "verify-email-button-1.1": "Comprobar estado de verificación",
  "verify-email-button-1.2": "Verificando…",
  "verify-email-button-1.3": "Correo electrónico no verificado",

  "verify-email-button-2.1": "Reenviar correo electrónico",
  "verify-email-button-2.2": "Reenviando…",
  "verify-email-button-2.3": "Correo electrónico enviado",

  "verify-email-verification-error-message": "Tu dirección de correo electrónico aún no se ha verificado.",

  "verify-email-toast": "Ten paciencia, el correo electrónico puede tardar unos minutos en aparecer en tu bandeja de entrada.",

  "change-email-title": "Cambiar correo electrónico",
  "change-email-text": "Introduce tu dirección de correo electrónico para que podamos reenviarte un correo electrónico para verificar.",
  "change-email-placeholder": "Nuevo correo electrónico",
  "change-email-button-1.1": "Cambiar correo electrónico",
  "change-email-button-1.2": "Cambiando…",

  "reset-password-title": "Restablecer contraseña",
  "reset-password-text": "Introduzca su dirección de correo electrónico y le <br/> enviaremos un correo electrónico con <br/> instrucciones para restablecer su contraseña.",
  "reset-password-placeholder": "Correo electrónico",
  "reset-password-button-text": "Enviar Correo Electrónico",
  "reset-password-success": "Por favor revise la dirección de correo <br/> electrónico {{email}} y busque un email <br/> conteniendo instrucciones acerca de como <br/> restablecer su contraseña.",
  "reset-password-retry": "Reenviar email"
}
