{
  "success-page-sub-logo-text": "アプリに1件の{{company_name}}アカウントがあります。",
  "success-page-text": "アプリケーションに戻るとセットアップを続行します。",

  "return-to-app-button-text": "{{app}}に戻る",

  "error-page-title": "申し訳ございません。",
  "error-page-text": "アカウントへの接続中に問題が発生しました。",
  "error-page-two-factor-code-expired-error": "2要素認証コードの期限が切れています",
  "error-page-need-help-text": "サポートが必要ですか？ <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">オンラインヘルプサイトにアクセス</a>",
  "error-page-try-again-button": "再試行",
  "error-page-blocked-user-error": "お客様のデータを保護するために、お使いのアカウントへのアクセスが無効になっています。アカウントへのアクセスを回復するには、パスワードをリセットする必要があります。これまで使用したことのない強力な一意のパスワードをお選びいただくことをお勧めいたします。",
  "error-page-blocked-user-error2": "お使いのアカウントへのアクセスが無効になっています。",
  "error-user-exists": "このメールアドレスはすでに使用されています",

  "back": "戻る",

  "inline-error": "問題が発生しました。 再試行してください。",

  "footer-1": "アプリに1件の{{company_name}}アカウントがあります。",
  "footer-2": "© 2022 {{company_name}} Corporation or its affiliates. All rights reserved.",

  "verify-email-title": "メールの確認",

  "verify-email-text-1": "アカウントが作成されました。{{company_name}}アカウントを適切に保護するために、メールアドレスを確認する必要があります。{{company_name}}から<b>{{email}}</b>に送信されたメールを確認してください。 メールに記載された手順に従ってこちらに戻り［<b>確認ステータスをチェック</b>］をクリックします。",
  "verify-email-text-2": "必要に応じて、{{company_name}}アカウントで使用する<b><a href=\"#\" data-action=\"verify-email#changeEmail\">メールアドレスを変更</a></b>できます。",

  "verify-email-button-1.1": "確認ステータスをチェック",
  "verify-email-button-1.2": "確認中...",
  "verify-email-button-1.3": "メールが確認されませんでした",

  "verify-email-button-2.1": "メールを再送信",
  "verify-email-button-2.2": "再送信中...",
  "verify-email-button-2.3": "メールを送信しました",

  "verify-email-verification-error-message": "メールアドレスはまだ確認されていません。",

  "verify-email-toast": "メールが送信されるまでにしばらくかかりますので、お待ちください。",

  "change-email-title": "メールアドレスを変更",
  "change-email-text": "確認メールを送信できるように、メールアドレスを入力してください。",
  "change-email-placeholder": "新しいメールアドレス",
  "change-email-button-1.1": "メールアドレスを変更",
  "change-email-button-1.2": "変更しています...",

  "reset-password-title": "パスワードのリセット",
  "reset-password-text": "メールアドレスを入力してください。パスワードのリセットに必要な手順が送信されます。",
  "reset-password-placeholder": "メールアドレス",
  "reset-password-button-text": "続ける",
  "reset-password-success": "パスワードのリセットに必要な手順の送信先メールアドレス {{email}} を確認してください。",
  "reset-password-retry": "メールの再送信"
}
