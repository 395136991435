import Controller from '../base';
import { state } from '../state';
import { BUTTON_STATES } from '../constants';
import { requestEmailVerification } from '../apis/authservice';
import { getUserinfo } from '../apis/auth0';
import t from '../translate';
import { authorize } from '../common';

const REQUEST_DONE_STATE_BUTTON_DELAY = 2000;
const TOAST_MESSAGE_TIMEOUT = 2000;
const MINUTE = 60_000;

export default class extends Controller {
  static values = {
    resendButtonState: { type: String, default: BUTTON_STATES.DEFAULT },
    checkButtonState: { type: String, default: BUTTON_STATES.DEFAULT },
    error: { type: Boolean, default: false },
    isRechecked: { type: Boolean, default: false },
    toastMessage: { type: String, default: '' },
    resendTime: { type: Number, default: 0 },
  };

  _render() {
    const user = state.get('user');
    const existing = user['https://wdc.com/brand'] !== undefined;

    this.render({
      email: user.email,
      existing,
      resendButtonState: this.resendButtonStateValue,
      checkButtonState: this.checkButtonStateValue,
      error: this.errorValue,
      isRechecked: this.isRecheckedValue,
      toastMessage: this.toastMessageValue,
    });
  }

  connect() { this._render(); }

  resendButtonStateValueChanged() { this._render(); }

  checkButtonStateValueChanged() { this._render(); }

  toastMessageValueChanged() { this._render(); }

  changeEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.APP.go(this.APP.ROUTES.CHANGE_EMAIL);
  };

  resendEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.resendTimeout) clearTimeout(this.resendTimeout);
    this.isRecheckedValue = false;
    this.errorValue = false;
    this.resendButtonStateValue = BUTTON_STATES.DEFAULT;

    const n = Date.now();
    if (this.resendTimeValue + MINUTE > n) {
      if (this.resendToastTimeout) clearTimeout(this.resendToastTimeout);
      this.toastMessageValue = t('verify-email-toast');
      this.resendToastTimeout = setTimeout(() => { this.toastMessageValue = ''; }, TOAST_MESSAGE_TIMEOUT);
      return;
    }

    this.resendTimeValue = n;
    this.resendButtonStateValue = BUTTON_STATES.REQUEST;

    requestEmailVerification({
      token: state.get('access_token'),
      userId: state.get('user').sub,
    }).then((resp) => {
      if (resp._status !== 201) throw new Error(resp._status);

      // change button state right after getting response
      this.resendButtonStateValue = BUTTON_STATES.REQUEST_DONE;

      // change button state to default after a delay
      this.resendTimeout = setTimeout(
        () => { this.resendButtonStateValue = BUTTON_STATES.DEFAULT; },
        REQUEST_DONE_STATE_BUTTON_DELAY,
      );
    }).catch(() => {
      this.errorValue = true;
      this.resendButtonStateValue = BUTTON_STATES.DEFAULT;
    });
  };

  checkUser = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.checkTimeout) clearTimeout(this.checkTimeout);
    this.isRecheckedValue = false;
    this.errorValue = false;
    this.checkButtonStateValue = BUTTON_STATES.REQUEST;

    getUserinfo({
      accessToken: state.get('access_token'),
    }).then((resp) => {
      if (resp._status !== 200) throw new Error(resp._status);

      // set udpated user info
      state.set('user', resp);

      const { email_verified } = resp;

      // check email verified.
      // if it is - push the user back to /authorize to re-authorize
      if (email_verified) {
        authorize({ skipVerification: true });
      } else {
        // change button state right after getting response
        this.checkButtonStateValue = BUTTON_STATES.REQUEST_DONE;
        this.isRecheckedValue = true;

        // change button state to default after a delay
        this.checkTimeout = setTimeout(
          () => { this.checkButtonStateValue = BUTTON_STATES.DEFAULT; },
          REQUEST_DONE_STATE_BUTTON_DELAY,
        );
      }
    }).catch(() => {
      this.errorValue = true;
      this.checkButtonStateValue = BUTTON_STATES.DEFAULT;
    });
  };
}
