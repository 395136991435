import Controller from '../base';
import { BUTTON_STATES } from '../constants';
import { resetPassword } from '../apis/auth0';
import { state } from '../state';
import t from '../translate';

export default class extends Controller {
  title = t('reset-password-title');

  static values = {
    submitState: { type: String, default: BUTTON_STATES.DEFAULT },
    input: { type: String, default: '' },
    error: { type: String, default: '' },
  };

  _render() {
    this.render({
      submitState: this.submitStateValue,
      value: this.inputValue,
      error: this.errorValue,
    });
  }

  connect() {
    this._render();
  }

  onInputChange = (e) => {
    this.inputValue = e.target.value;
  };

  submitStateValueChanged() { this._render(); }

  resetForm() {
    this.submitStateValue = BUTTON_STATES.DEFAULT;
    this.errorValue = '';
  }

  submit = (e) => {
    const formData = new FormData(e.target);
    const clientId = state.get('clientId');

    e.preventDefault();
    e.stopPropagation();

    this.errorValue = '';
    this.submitStateValue = BUTTON_STATES.REQUEST;

    resetPassword({
      email: formData.get('email'),
      clientId,
    }).then((r) => {
      if (r._status !== 200) throw new Error(r._status);
      // success message on 200
      this.submitStateValue = BUTTON_STATES.REQUEST_DONE;
    }).catch(() => {
      this.errorValue = t('inline-error');
      // return to default message to prompt a retry
      this.submitStateValue = BUTTON_STATES.DEFAULT;
    });
  };
}
