{
  "success-page-sub-logo-text": "Ένας λογαριασμός {{company_name}} για τις εφαρμογές σας.",
  "success-page-text": "Επιστρέψτε στην εφαρμογή για να συνεχίσετε τη ρύθμιση.",

  "return-to-app-button-text": "Επιστροφή στο {{app}}",

  "error-page-title": "Ωχ!",
  "error-page-text": "Παρουσιάστηκε κάποιο σφάλμα με τη σύνδεση στον λογαριασμό σας.",
  "error-page-two-factor-code-expired-error": "Ο κωδικός ελέγχου ταυτότητας δύο παραγόντων έληξε",
  "error-page-need-help-text": "Χρειάζεστε βοήθεια; <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Επισκεφτείτε τον ιστότοπο ηλεκτρονικής βοήθειας που διαθέτουμε</a>",
  "error-page-try-again-button": "Προσπαθήστε ξανά",
  "error-page-blocked-user-error": "Για την προστασία των δεδομένων σας, η πρόσβαση στο λογαριασμό σας έχει απενεργοποιηθεί. Για να επανακτήσετε την πρόσβαση στον λογαριασμό σας, πρέπει να κάνετε επαναφορά του κωδικού πρόσβασής σας. Σας προτείνουμε να επιλέξετε έναν ισχυρό, μοναδικό κωδικό πρόσβασης που δεν έχετε χρησιμοποιήσει ποτέ στο παρελθόν.",
  "error-page-blocked-user-error2": "Η πρόσβαση στον λογαριασμό σας έχει απενεργοποιηθεί.",
  "error-user-exists": "Η διεύθυνση email χρησιμοποιείται ήδη",

  "back": "Πίσω όψη",

  "inline-error": "Παρουσιάστηκε πρόβλημα. Προσπαθήστε ξανά.",

  "footer-1": "Ένας λογαριασμός {{company_name}} για τις εφαρμογές σας.",
  "footer-2": "© 2022 {{company_name}} Corporation ή οι θυγατρικές της. Με επιφύλαξη παντός δικαιώματος.",

  "verify-email-title": "Επαληθεύστε τη διεύθυνση email σας",

  "verify-email-text-1": "Δημιουργήθηκε ο λογαριασμός σας. Για να προστατεύσετε καλύτερα τον λογαριασμό σας {{company_name}}, πρέπει να επαληθεύσουμε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας. Ελέγξτε για ένα μήνυμα ηλεκτρονικού ταχυδρομείου απεσταλμένο από {{company_name}} σε <b>{{email}}</b>. Ακολουθήστε τις οδηγίες στο μήνυμα ηλεκτρονικού ταχυδρομείου και στη συνέχεια, επιστρέψετε εδώ για να κάνετε κλικ στο <b>Έλεγχος κατάστασης επαλήθευσης</b>.",
  "verify-email-text-2": "Εάν θέλετε, μπορείτε να <b><a href=\"#\" data-action=\"verify-email#changeEmail\">αλλάξετε τη διεύθυνση ηλεκτρονικού ταχυδρομείου</a></b> που χρησιμοποιείτε για τον λογαριασμό {{company_name}} σας.",

  "verify-email-button-1.1": "Έλεγχος κατάστασης επαλήθευσης",
  "verify-email-button-1.2": "Επαλήθευση…",
  "verify-email-button-1.3": "Η διεύθυνση ηλεκτρονικού ταχυδρομείου δεν επαληθεύτηκε",

  "verify-email-button-2.1": "Εκ νέου αποστολή μηνύματος ηλεκτρονικού ταχυδρομείου",
  "verify-email-button-2.2": "Εκ νέου αποστολή…",
  "verify-email-button-2.3": "Το μήνυμα ηλεκτρονικού ταχυδρομείου εστάλη",

  "verify-email-verification-error-message": "Η διεύθυνση ηλεκτρονικού ταχυδρομείου σας δεν επαληθεύτηκε ακόμη.",

  "verify-email-toast": "Κάντε υπομονή, μπορεί να χρειαστούν λίγα λεπτά για να εμφανιστεί το μήνυμα στα εισερχόμενά σας.",

  "change-email-title": "Αλλαγή διεύθυνσης ηλεκτρονικού ταχυδρομείου",
  "change-email-text": "Εισαγάγετε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας, ώστε να μπορέσουμε να σας στείλουμε ένα μήνυμα επαλήθευσης.",
  "change-email-placeholder": "Νέα διεύθυνση ηλεκτρονικού ταχυδρομείου",
  "change-email-button-1.1": "Αλλαγή διεύθυνσης ηλεκτρονικού ταχυδρομείου",
  "change-email-button-1.2": "Αλλαγή σε εξέλιξη…",

  "reset-password-title": "Επαναφορά κωδικού πρόσβασης",
  "reset-password-text": "Εισαγάγετε τη διεύθυνση email σας και θα σας <br/> στείλουμε οδηγίες <br> επαναφοράς του κωδικού σας.",
  "reset-password-placeholder": "Διεύθυνση email",
  "reset-password-button-text": "Συνέχεια",
  "reset-password-success": "Ελέγξτε τη διεύθυνση email <br/>{{email}} για οδηγίες <br/> επαναφοράς του κωδικού σας.",
  "reset-password-retry": "Εκ νέου αποστολή email"
}
