{
  "success-page-sub-logo-text": "Ein {{company_name}}-Konto für Ihre Apps.",
  "success-page-text": "Kehren Sie zur Anwendung zurück, um die Einrichtung fortzusetzen.",

  "return-to-app-button-text": "Zu {{app}} zurückkehren",

  "error-page-title": "Ups!",
  "error-page-text": "Bei der Verbindung zu Ihrem Konto ist ein Fehler aufgetreten.",
  "error-page-two-factor-code-expired-error": "Zwei-Faktor-Authentifizierungscode abgelaufen",
  "error-page-need-help-text": "Benötigen Sie Hilfe? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Besuchen Sie unsere Hilfeseite im Internet</a>",
  "error-page-try-again-button": "Versuchen Sie es erneut",
  "error-page-blocked-user-error": "Zum Schutz Ihrer Daten wurde Ihr Konto deaktiviert. Um den Zugriff auf Ihr Konto wiederherzustellen, müssen Sie Ihr Passwort zurücksetzen. Wir empfehlen, ein sicheres, einmaliges Passwort auszuwählen, das Sie noch nie verwendet haben.",
  "error-page-blocked-user-error2": "Der Zugriff auf Ihr Konto wurde deaktiviert.",
  "error-user-exists": "Diese E-Mail-Adresse wird bereits verwendet",

  "back": "Zurück",

  "inline-error": "Es ist ein Problem aufgetreten. Versuchen Sie es erneut.",

  "footer-1": "Ein {{company_name}}-Konto für Ihre Apps.",
  "footer-2": "©2022 {{company_name}} Corporation oder ihre Tochtergesellschaften. Alle Rechte vorbehalten.",

  "verify-email-title": "E-Mail-Adresse bestätigen",

  "verify-email-text-1": "Ihr Konto wurde erstellt. Um Ihr {{company_name}}-Konto besser zu schützen, müssen Sie Ihre E-Mail-Adresse verifizieren. Suchen Sie nach einer E-Mail von {{company_name}} an <b>{{email}}</b>. Befolgen Sie die Anweisungen in der E-Mail, kehren Sie dann hierher zurück und klicken Sie auf <b>Verifizierungsstatus prüfen</b>.",
  "verify-email-text-2": "Wenn es notwendig ist, können Sie <b><a href=\"#\" data-action=\"verify-email#changeEmail\">die E-Mail-Adresse ändern</a></b>, die Sie für Ihr {{company_name}}-Konto verwenden.",

  "verify-email-button-1.1": "Verifizierungsstatus prüfen",
  "verify-email-button-1.2": "Wird verifiziert …",
  "verify-email-button-1.3": "E-Mail nicht verifiziert",

  "verify-email-button-2.1": "E-Mail erneut senden",
  "verify-email-button-2.2": "Wird erneut gesendet …",
  "verify-email-button-2.3": "E-Mail gesendet",

  "verify-email-verification-error-message": "Ihre E-Mail-Adresse wurde noch nicht verifiziert.",

  "verify-email-toast": "Haben Sie Geduld, es kann ein paar Minuten dauern, bis die E-Mail in Ihrem Posteingang angezeigt wird.",

  "change-email-title": "E-Mail-Adresse ändern",
  "change-email-text": "Geben Sie Ihre E-Mail-Adresse ein, damit wir Ihnen eine E-Mail zur Verifizierung schicken können.",
  "change-email-placeholder": "Neue E-Mail",
  "change-email-button-1.1": "E-Mail-Adresse ändern",
  "change-email-button-1.2": "Ändern …",

  "reset-password-title": "Passwort zurücksetzen",
  "reset-password-text": "Geben Sie Ihre E-Mail-Adresse ein,<br/> und wir senden Ihnen eine Anleitung <br/> zum Zurücksetzen Ihres Passworts zu.",
  "reset-password-placeholder": "E-Mail-Adresse",
  "reset-password-button-text": "Fortfahren",
  "reset-password-success": "Bitte überprüfen Sie die E-Mail-Adresse <br/> {{email}}, um die Anleitung zum <br/> Zurücksetzen Ihres Passworts zu erhalten.",
  "reset-password-retry": "E-Mail erneut senden"
}
