{
  "success-page-sub-logo-text": "用于您的应用程序的一个 {{company_name}} 帐户。",
  "success-page-text": "返回应用程序以继续设置。",

  "return-to-app-button-text": "返回 {{app}}",

  "error-page-title": "糟糕！",
  "error-page-text": "我们在连接您的帐户时遇到问题。",
  "error-page-two-factor-code-expired-error": "双因素身份验证代码已过期",
  "error-page-need-help-text": "需要帮助？ <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">访问在线帮助网站</a>",
  "error-page-try-again-button": "重试",
  "error-page-blocked-user-error": "为保护数据，对您帐户的访问已被禁止。要重新访问帐户，您必须重置密码。我们建议您选择一个从未使用过、强度高且单独使用的密码。",
  "error-page-blocked-user-error2": "对您帐户的访问已禁止。",
  "error-user-exists": "此电子邮件地址已被使用",

  "back": "返回",

  "inline-error": "出现问题。请重试。",

  "footer-1": "用于您的应用程序的一个 {{company_name}} 帐户。",
  "footer-2": "© 2022 {{company_name}} Corporation 或其附属公司。保留所有权利。",

  "verify-email-title": "验证您的电子邮件",

  "verify-email-text-1": "您的帐户已创建。为了更好地保护您的 {{company_name}} 帐户，您必须验证您的电子邮件地址。检查从 {{company_name}} 发送到 <b>{{email}}</b> 的电子邮件。请按照电子邮件中的说明进行操作，然后返回此处单击<b>检查验证状态</b>。",
  "verify-email-text-2": "如果需要，您可以<b><a href=\"#\" data-action=\"verify-email#changeEmail\">更改用于 {{company_name}} 帐户的电子邮件地址</a></b>。",

  "verify-email-button-1.1": "检查验证状态",
  "verify-email-button-1.2": "正在验证...",
  "verify-email-button-1.3": "电子邮件未验证",

  "verify-email-button-2.1": "重新发送电子邮件",
  "verify-email-button-2.2": "正在重新发送...",
  "verify-email-button-2.3": "电子邮件已发送",

  "verify-email-verification-error-message": "您的电子邮件地址尚未验证。",

  "verify-email-toast": "请耐心等待，电子邮件可能需要几分钟才会出现在您的收件箱中。",

  "change-email-title": "更改电子邮件",
  "change-email-text": "请输入您的电子邮件地址，以便我们向您发送验证电子邮件。",
  "change-email-placeholder": "新电子邮件",
  "change-email-button-1.1": "更改电子邮件",
  "change-email-button-1.2": "正在更改...",

  "reset-password-title": "重设密码",
  "reset-password-text": "请输入您的电子邮件地址，我们将向您发送重置密码的说明。",
  "reset-password-placeholder": "电子邮件地址",
  "reset-password-button-text": "继续",
  "reset-password-success": "请检查电子邮件地址 {{email}}，<br/> 了解重置密码的说明。",
  "reset-password-retry": "重新发送电子邮件"
}
