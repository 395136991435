{
  "success-page-sub-logo-text": "Egy {{company_name}}-fiók az alkalmazásokhoz.",
  "success-page-text": "A beállítás folytatásához lépjen vissza az alkalmazásba.",

  "return-to-app-button-text": "Visszatérés a(z) {{app}} alkalmazáshoz",

  "error-page-title": "Hoppá!",
  "error-page-text": "Hiba lépett fel a fiókjához való csatlakozás közben.",
  "error-page-two-factor-code-expired-error": "A kétfaktoros hitelesítési kód lejárt",
  "error-page-need-help-text": "Segítségre van szüksége?<a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Látogasson el online súgóoldalunkra</a>",
  "error-page-try-again-button": "Próbálkozzon újra",
  "error-page-blocked-user-error": "Adatai védelme érdekében a fiókjához való hozzáférést letiltottuk. A fiókjához való hozzáférés visszaszerzéséhez alaphelyzetbe kell állítania jelszavát. Azt javasoljuk, válasszon egy erős, egyedi jelszót, amelyet korábban még nem használt.",
  "error-page-blocked-user-error2": "A fiókjához való hozzáférést letiltottuk.",
  "error-user-exists": "Ez az e-mail cím már használatban van",

  "back": "Vissza",

  "inline-error": "Hiba történt.Próbálkozzon újra.",

  "footer-1": "Egy {{company_name}}-fiók az alkalmazásokhoz.",
  "footer-2": "© 2022 {{company_name}} Corporation vagy társvállalatai. Minden jog fenntartva.",

  "verify-email-title": "E-mail-cím megerősítése",

  "verify-email-text-1": "A fiókja létrejött.A {{company_name}}-fiók hatékonyabb védelme érdekében meg kell erősítenünk az e-mail-címét.Ellenőrizze a(z) {{company_name}} által a következő címre küldött e-mailt: <b>{{email}}</b>.Kövesse az e-mailben kapott utasításokat, majd térjen vissza ide, és kattintson a <b>Hitelesítési állapot ellenőrzése</b> elemre.",
  "verify-email-text-2": "Szükség esetén <b><a href=\"#\" data-action=\"verify-email#changeEmail\">módosíthatja a {{company_name}}-fiókhoz használt e-mail-címet</a></b>.",

  "verify-email-button-1.1": "Hitelesítési állapot ellenőrzése",
  "verify-email-button-1.2": "Hitelesítés...",
  "verify-email-button-1.3": "Az e-mail-cím nincs hitelesítve",

  "verify-email-button-2.1": "E-mail újraküldése",
  "verify-email-button-2.2": "Újraküldés...",
  "verify-email-button-2.3": "E-mail elküldve",

  "verify-email-verification-error-message": "Az Ön e-mail-címe még nincs hitelesítve.",

  "verify-email-toast": "Kis türelmet, néhány percet igénybe vehet, amíg az e-mail megjelenik a postaládájában.",

  "change-email-title": "E-mail-cím módosítása",
  "change-email-text": "Adja meg az e-mail-címét, hogy elküldhessünk egy e-mailt a hitelesítéshez.",
  "change-email-placeholder": "Új e-mail-cím",
  "change-email-button-1.1": "E-mail-cím módosítása",
  "change-email-button-1.2": "Módosítás...",

  "reset-password-title": "Jelszó alaphelyzetbe állítása",
  "reset-password-text": "Adja meg az e-mail-címét, és elküldjük Önnek <br/> a jelszó visszaállításához szükséges utasításokat.",
  "reset-password-placeholder": "E-mail-cím",
  "reset-password-button-text": "Folytatás",
  "reset-password-success": "Kérjük, ellenőrizze a jelszó visszaállításához <br/> szükséges utasítások kézbesítésére szolgáló <br/> {{email}} e-mail-címet .",
  "reset-password-retry": "E-mail újraküldése"
}
