{
  "success-page-sub-logo-text": "Yksi {{company_name}}-tili sovelluksillesi.",
  "success-page-text": "Palaa sovellukseen jatkaaksesi asennusta.",

  "return-to-app-button-text": "Palaa kohteeseen {{app}}",

  "error-page-title": "Hupsis!",
  "error-page-text": "Havaitsimme ongelman, kun yritimme muodostaa yhteyden tiliisi.",
  "error-page-two-factor-code-expired-error": "Kaksiosainen todennuskoodi vanhentui",
  "error-page-need-help-text": "Tarvitsetko apua? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Käy verkko-ohjesivustollamme</a>",
  "error-page-try-again-button": "Yritä uudelleen",
  "error-page-blocked-user-error": "Tilisi on poistettu käytöstä, jotta tietojasi voidaan suojella. Palauta tilin käyttöoikeudet asettamalla salasanasi uudelleen. Suosittelemme, että valitset vahvan ja ainutlaatuisen salasanan, jota et ole käyttänyt koskaan aiemmin.",
  "error-page-blocked-user-error2": "Käyttäjätilisi on poistettu käytöstä.",
  "error-user-exists": "Tätä sähköpostiosoitetta on jo käytetty",

  "back": "Takaisin",

  "inline-error": "Jokin meni vikaan. Yritä uudelleen.",

  "footer-1": "Yksi {{company_name}}-tili sovelluksillesi.",
  "footer-2": "© 2022 {{company_name}} Corporation tai sen tytäryhtiöt. Kaikki oikeudet pidätetään.",

  "verify-email-title": "Vahvista sähköpostiosoitteesi",

  "verify-email-text-1": "Tilisi on luotu. Sinun on vahvistettava sähköpostiosoitteesi, jotta voimme suojata {{company_name}} -tilisi. Katso sähköposti, joka on lähetetty kohteesta {{company_name}} kohteeseen <b>{{email}}</b>. Noudata sähköpostin ohjeita ja palaa takaisin tähän napsauttaaksesi <b>Tarkista vahvistustila</b>.",
  "verify-email-text-2": "Voit tarvittaessa <b><a href=\"#\" data-action=\"verify-email#changeEmail\">vaihtaa sähköpostiosoitetta</a></b>, jota käytät {{company_name}}-tilille.",

  "verify-email-button-1.1": "Tarkista vahvistustila",
  "verify-email-button-1.2": "Vahvistetaan...",
  "verify-email-button-1.3": "Sähköposti ei ole vahvistettu",

  "verify-email-button-2.1": "Lähetä sähköposti uudelleen",
  "verify-email-button-2.2": "Lähetetään uudelleen...",
  "verify-email-button-2.3": "Sähköposti lähetetty",

  "verify-email-verification-error-message": "Sähköpostiosoitettasi ei ole vielä vahvistettu.",

  "verify-email-toast": "Ole kärsivällinen, sähköpostin saapumisessa saattaa kestää muutama minuutti.",

  "change-email-title": "Vaihda sähköpostiosoite",
  "change-email-text": "Syötä sähköpostiosoitteesi, jotta voimme lähettää sinulle vahvistettavan sähköpostin.",
  "change-email-placeholder": "Uusi sähköposti",
  "change-email-button-1.1": "Vaihda sähköpostiosoite",
  "change-email-button-1.2": "Vaihdetaan...",

  "reset-password-title": "Aseta salasana uudelleen",
  "reset-password-text": "Anna sähköpostiosoitteesi, ja lähetämme <br/> sinulle ohjeet salasanan vaihtamiseen.",
  "reset-password-placeholder": "Sähköpostiosoite",
  "reset-password-button-text": "Jatkaa",
  "reset-password-success": "Sähköpostiosoitteessa {{email}} <br/> on ohjeet salasanan vaihtamiseen.",
  "reset-password-retry": "Lähetä sähköpostiviesti uudelleen"
}
