{
  "success-page-sub-logo-text": "Uygulamalarınız için bir {{company_name}} Hesabı.",
  "success-page-text": "Kuruluma devam etmek için uygulamaya geri dönün.",

  "return-to-app-button-text": "{{app}} öğesine geri dön",

  "error-page-title": "Eyvah!",
  "error-page-text": "Hesabınızı bağlarken bir sorunla karşılaştık.",
  "error-page-two-factor-code-expired-error": "İki faktörlü doğrulama kodunun süresi doldu",
  "error-page-need-help-text": "Yardıma mı ihtiyacınız var? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Çevrimiçi yardım sitemizi ziyaret edin</a>",
  "error-page-try-again-button": "Tekrar deneyin",
  "error-page-blocked-user-error": "Verilerinizi korumak için hesabınız devre dışı bırakıldı. Hesabınıza yeniden erişim kazanmak için parolanızı sıfırlamanız gerekir. Daha önce kullanmadığınız güçlü, benzersiz bir parola seçmenizi öneriyoruz.",
  "error-page-blocked-user-error2": "Hesabınıza erişim devre dışı bırakıldı.",
  "error-user-exists": "Bu e-posta adresi zaten kullanılıyor",

  "back": "Geri",

  "inline-error": "Bir şeyler ters gitti. Tekrar deneyin.",

  "footer-1": "Uygulamalarınız için bir {{company_name}} Hesabı.",
  "footer-2": "© 2022 {{company_name}} Corporation veya iştriakleri. Tüm hakları saklıdır.",

  "verify-email-title": "E-postanızı doğrulayın",

  "verify-email-text-1": "Hesabınız oluşturuldu. {{company_name}} Hesabınızı daha iyi korumak için e-posta adresinizi doğrulamamız gerekiyor. {{company_name}} şirketinden <b>{{email}}</b> adresine e-posta gelip gelmediğini kontrol edin. E-postadaki talimatları uygulayın, sonrasında buraya geri dönerek <b>Doğrulama durumunu kontrol et</b> öğesine tıklayın.",
  "verify-email-text-2": "İhtiyaç duyarsanız, {{company_name}} hesabınız için kullandığınız <b><a href=\"#\" data-action=\"verify-email#changeEmail\">e-posta adresini değiştirebilirsiniz</a></b>.",

  "verify-email-button-1.1": "Doğrulama durumunu kontrol et",
  "verify-email-button-1.2": "Doğrulanıyor...",
  "verify-email-button-1.3": "E-posta doğrulanmadı",

  "verify-email-button-2.1": "E-postayı yeniden gönder",
  "verify-email-button-2.2": "Yeniden gönderiliyor...",
  "verify-email-button-2.3": "E-posta gönderildi",

  "verify-email-verification-error-message": "E-posta adresiniz henüz doğrulanmadı.",

  "verify-email-toast": "Sabırlı olun, e-postanın gelen kutunuza düşmesi birkaç dakika sürebilir.",

  "change-email-title": "E-postayı değiştir",
  "change-email-text": "Size doğrulama e-postası gönderebilmemiz için e-posta adresinizi girin.",
  "change-email-placeholder": "Yeni e-posta",
  "change-email-button-1.1": "E-postayı değiştir",
  "change-email-button-1.2": "Değiştiriliyor...",

  "reset-password-title": "Şifreyi Sıfırla",
  "reset-password-text": "E-posta adresinizi girin, <br/> parolanızı sıfırlamak için size <br/> talimatlar göndereceğiz.",
  "reset-password-placeholder": "E-posta adresi",
  "reset-password-button-text": "Devam",
  "reset-password-success": "Parolanızı sıfırlama talimatları için <br/> lütfen {{email}} e-posta adresini kontrol edin.",
  "reset-password-retry": "E-postayı yeniden gönder"
}
