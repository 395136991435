import Cookies from 'js-cookie';

import Controller from '../base';
import { state, SUCCESS_LINK_HREF } from '../state';
import { COOKIES_BASED_SKIP_VERIFICATION } from '../features';

const open = () => window.location.replace(SUCCESS_LINK_HREF());

export default class extends Controller {
  static targets = ['image'];

  connect() {
    const cookie = Cookies.get('skipVerification');

    if (
      COOKIES_BASED_SKIP_VERIFICATION
      && cookie === state.get('state')
    ) {
      Cookies.remove('skipVerification');
    }

    if (state.get('url')) {
      open();
    } else {
      this.render({ href: SUCCESS_LINK_HREF() });

      if (this.imageTarget.complete) {
        open();
      } else {
        this.imageTarget.addEventListener('load', open);
        this.imageTarget.addEventListener('error', open);
      }
    }
  }
}
