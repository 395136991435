{
  "success-page-sub-logo-text": "Jedno konto {{company_name}} dla Twoich aplikacji.",
  "success-page-text": "Wróć do aplikacji, aby kontynuować konfigurację.",

  "return-to-app-button-text": "Wróć do {{app}}",

  "error-page-title": "Ups!",
  "error-page-text": "Nastąpił problem podczas próby połączenia z kontem.",
  "error-page-two-factor-code-expired-error": "Kod uwierzytelniania dwuskładnikowego wygasł",
  "error-page-need-help-text": "Potrzebujesz pomocy? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Odwiedź naszą stronę pomocy</a>",
  "error-page-try-again-button": "Spróbuj ponownie",
  "error-page-blocked-user-error": "Dostęp do Twojego konta został zablokowany w celu ochrony danych. Aby odzyskać dostęp do konta, musisz zresetować hasło. Sugerujemy podanie silnego, nietypowego hasła, które nie było jeszcze używane.",
  "error-page-blocked-user-error2": "Dostęp do Twojego konta został zablokowany.",
  "error-user-exists": "Ten adres e-mail jest już używany",

  "back": "Wstecz",

  "inline-error": "Coś poszło nie tak. Spróbuj ponownie.",

  "footer-1": "Jedno konto {{company_name}} dla Twoich aplikacji.",
  "footer-2": "© 2022 {{company_name}} Corporation lub jej podmioty zależne. Wszelkie prawa zastrzeżone.",

  "verify-email-title": "Zweryfikuj swój adres e-mail",

  "verify-email-text-1": "Utworzono konto. Aby lepiej chronić Twoje konto w {{company_name}}, musisz zweryfikować swój adres e-mail. Sprawdź, czy otrzymałeś wiadomość e-mail z {{company_name}} na <b>{{email}}</b>. Postępuj zgodnie z instrukcjami zawartymi w wiadomości e-mail, a następnie wróć tutaj i kliknij <b>Sprawdź status weryfikacji</b>.",
  "verify-email-text-2": "W razie potrzeby możesz <b><a href=\"#\" data-action=\"verify-email#changeEmail\">zmienić adres e-mail</a></b> używany do obsługi {{company_name}} konta.",

  "verify-email-button-1.1": "Sprawdź status weryfikacji",
  "verify-email-button-1.2": "Weryfikowanie…",
  "verify-email-button-1.3": "Adres e-mail niezweryfikowany",

  "verify-email-button-2.1": "Ponownie wyślij wiadomość e-mail",
  "verify-email-button-2.2": "Ponowne wysyłanie…",
  "verify-email-button-2.3": "Wysłano wiadomość e-mail",

  "verify-email-verification-error-message": "Twój adres e-mail nie jest jeszcze zweryfikowany.",

  "verify-email-toast": "Bądź cierpliwy, może minąć kilka minut, zanim wiadomość e-mail pojawi się w skrzynce odbiorczej.",

  "change-email-title": "Zmień e-mail",
  "change-email-text": "Wpisz swój adres e-mail, abyśmy mogli wysłać Ci wiadomość w celu weryfikacji.",
  "change-email-placeholder": "Nowy e-mail",
  "change-email-button-1.1": "Zmień e-mail",
  "change-email-button-1.2": "Zmienianie…",

  "reset-password-title": "Resetowanie hasła",
  "reset-password-text": "Wpisz swój adres e-mail, <br/> a my wyślemy Ci instrukcje resetowania hasła.",
  "reset-password-placeholder": "Adres e-mail",
  "reset-password-button-text": "Kontynuuj",
  "reset-password-success": "Sprawdź adres e-mail {{email}}, <br/> aby uzyskać instrukcje dotyczące <br/> resetowania hasła.",
  "reset-password-retry": "Wyślij email ponownie"
}
