{
  "success-page-sub-logo-text": "En {{company_name}}-konto for dine apps.",
  "success-page-text": "Vend tilbage til applikationen for at fortsætte opsætningen.",

  "return-to-app-button-text": "Vend tilbage til {{app}}",

  "error-page-title": "Hovsa!",
  "error-page-text": "Vi stødte på et problem med at oprette forbindelse til din konto.",
  "error-page-two-factor-code-expired-error": "Tofaktorgodkendelseskode udløbet",
  "error-page-need-help-text": "Brug for hjælp? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Besøg vores online hjælpeside</a>",
  "error-page-try-again-button": "Prøv igen",
  "error-page-blocked-user-error": "For at beskytte dine data er adgangen til din konto blevet deaktiveret. For at få adgang til din konto igen, skal du nulstille din adgangskode. Vi foreslår, at du vælger en stærk, unik adgangskode, som du aldrig har brugt før.",
  "error-page-blocked-user-error2": "Adgang til din konto er blevet deaktiveret.",
  "error-user-exists": "Denne e-mailadresse er allerede brugt",

  "back": "Tilbage",

  "inline-error": "Der gik noget galt. Prøv igen.",

  "footer-1": "En {{company_name}}-konto for dine apps.",
  "footer-2": "© 2022 {{company_name}} Corporation eller dets datterselskaber. Alle rettigheder forbeholdes.",

  "verify-email-title": "Bekræft din e-mail",

  "verify-email-text-1": "Din konto er oprettet. For bedre at beskytte din {{company_name}}-konto, skal du bekræfte din e-mailadresse. Se efter en e-mail sendt fra {{company_name}} til <b>{{email}}</b>. Følg instruktionerne i e-mailen, og vend derefter tilbage hertil for at klikke på <b>Tjek bekræftelsesstatus</b>.",
  "verify-email-text-2": "Hvis du har brug for det, kan du <b><a href=\"#\" data-action=\"verify-email#changeEmail\">ændre e-mailadressen</a></b>, du bruger til din {{company_name}} konto.",

  "verify-email-button-1.1": "Tjek bekræftelsesstatus",
  "verify-email-button-1.2": "Bekræfter...",
  "verify-email-button-1.3": "E-mail er ikke bekræftet",

  "verify-email-button-2.1": "Send e-mail igen",
  "verify-email-button-2.2": "Sender igen...",
  "verify-email-button-2.3": "E-mail sendt",

  "verify-email-verification-error-message": "Din e-mailadresse er ikke bekræftet endnu.",

  "verify-email-toast": "Vær tålmodig, det kan tage et par minutter, før e-mailen vises i din indbakke.",

  "change-email-title": "Skift e-mail",
  "change-email-text": "Indtast din e-mailadresse, så vi kan sende dig en e-mail til bekræftelse.",
  "change-email-placeholder": "Ny e-mail",
  "change-email-button-1.1": "Skift e-mail",
  "change-email-button-1.2": "Skifter…",

  "reset-password-title": "Nulstil adgangskode",
  "reset-password-text": "Indtast din e-mailadresse, <br/> så sender vi dig en vejledning til, <br/> hvordan du nulstiller din adgangskode.",
  "reset-password-placeholder": "E-mailadresse",
  "reset-password-button-text": "Fortsæt",
  "reset-password-success": "Se e-mailadressen {{email}} <br/> for at få vejledning til nulstilling af adgangskoden.",
  "reset-password-retry": "Send e-mail igen"
}
