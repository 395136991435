{
  "success-page-sub-logo-text": "Én {{company_name}}-konto til alle appene dine.",
  "success-page-text": "Gå tilbake til applikasjonen for å fortsette installasjonen.",

  "return-to-app-button-text": "Gå tilbake til {{app}}",

  "error-page-title": "Uff da!",
  "error-page-text": "Vi oppdaget et problem da vi koblet til kontoen din.",
  "error-page-two-factor-code-expired-error": "Kode for tofaktorautentisering utløpt",
  "error-page-need-help-text": "Trenger du hjelp? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Besøk hjelpesiden vår på nettet</a>",
  "error-page-try-again-button": "Prøv på nytt",
  "error-page-blocked-user-error": "For å beskytte dataene dine, er tilgang til kontoen din deaktivert. For å få tilgang til kontoen din igjen, må du tilbakestille passordet. Vi anbefaler at du velger et sterkt, unikt passord som du aldri har brukt før.",
  "error-page-blocked-user-error2": "Tilgang til kontoen din deaktivert.",
  "error-user-exists": "Denne e-postadressen er allerede i bruk",

  "back": "Tilbake",

  "inline-error": "Noe gikk galt. Prøv på nytt.",

  "footer-1": "Én {{company_name}}-konto til alle appene dine.",
  "footer-2": "© 2022 {{company_name}} Corporation eller deres tilknyttede selskaper. Med enerett.",

  "verify-email-title": "Bekreft e-postadressen din",

  "verify-email-text-1": "Kontoen din har blitt opprettet. For bedre å beskytte {{company_name}}-kontoen din, må vi bekrefte e-postadressen din. Se etter en e-post som ble sendt fra {{company_name}} til <b>{{email}}</b>. Følg instruksjonene i e-posten og kom tilbake hit og klikk på <b>Sjekk bekreftelsesstatus</b>.",
  "verify-email-text-2": "Om du vil, kan du <b><a href=\"#\" data-action=\"verify-email#changeEmail\">endre e-postadressen</a></b> du bruker med {{company_name}}-kontoen din.",

  "verify-email-button-1.1": "Sjekk bekreftelsesstatus",
  "verify-email-button-1.2": "Bekrefter ...",
  "verify-email-button-1.3": "E-postadressen ble ikke bekreftet",

  "verify-email-button-2.1": "Send e-post på nytt",
  "verify-email-button-2.2": "Sender på nytt ...",
  "verify-email-button-2.3": "E-post sendt",

  "verify-email-verification-error-message": "E-postadressen din er ikke bekreftet ennå.",

  "verify-email-toast": "Vær tålmodig! Det kan ta noen minutter før e-posten dukker opp i innboksen din.",

  "change-email-title": "Endre e-postadresse",
  "change-email-text": "Angi e-postadressen din, slik at vi kan sende deg en e-post for å bekrefte adressen.",
  "change-email-placeholder": "Ny e-post",
  "change-email-button-1.1": "Endre e-postadresse",
  "change-email-button-1.2": "Endrer ...",

  "reset-password-title": "Tilbakestill passord",
  "reset-password-text": "Skriv inn e-postadressen din, <br/> så sender vi deg instruksjoner for å tilbakestille passordet.",
  "reset-password-placeholder": "E-postadresse",
  "reset-password-button-text": "Fortsett",
  "reset-password-success": "Sjekk e-postadressen {{email}} <br/> for å få instruksjoner om hvordan <br/> du tilbakestiller passordet.",
  "reset-password-retry": "Send e-post på nytt"
}
