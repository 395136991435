{
  "success-page-sub-logo-text": "Un Account {{company_name}} per le tue app.",
  "success-page-text": "Torna all'applicazione per procedere con la configurazione.",

  "return-to-app-button-text": "Torna a {{app}}",

  "error-page-title": "Ops",
  "error-page-text": "Si è verificato un problema di connessione al tuo account.",
  "error-page-two-factor-code-expired-error": "Codice di autenticazione a due fattori scaduto",
  "error-page-need-help-text": "Serve aiuto? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Consulta la nostra guida online</a>",
  "error-page-try-again-button": "Riprova",
  "error-page-blocked-user-error": "Per proteggere i tuoi dati, l'accesso al tuo account è stato disabilitato. Per accedere di nuovo all’account, è necessario reimpostare la password. Ti suggeriamo di scegliere una password complessa e univoca, mai usata prima.",
  "error-page-blocked-user-error2": "L'accesso al tuo account è stato disabilitato.",
  "error-user-exists": "Questo indirizzo e-mail è già stato utilizzato",

  "back": "Indietro",

  "inline-error": "Si è verificato un errore. Riprova.",

  "footer-1": "Un Account {{company_name}} per le tue app.",
  "footer-2": "© 2022 {{company_name}} Corporation o sue affiliate. Tutti i diritti riservati.",

  "verify-email-title": "Verifica l'indirizzo e-mail",

  "verify-email-text-1": "Il tuo account è stato creato. Per proteggere al meglio il tuo account {{company_name}}, dobbiamo verificare il tuo indirizzo e-mail. Verifica la presenza di un'email inviata da {{company_name}} a <b>{{email}}</b>. Segui le istruzioni contenute nell'e-mail e poi torna qui per cliccare su <b>Controlla lo stato della verifica</b>.",
  "verify-email-text-2": "Se lo desideri, puoi <b><a href=\"#\" data-action=\"verify-email#changeEmail\">cambiare l'indirizzo e-mail</a></b> che utilizzi per il tuo account {{company_name}}.",

  "verify-email-button-1.1": "Controlla lo stato della verifica",
  "verify-email-button-1.2": "Verifica in corso...",
  "verify-email-button-1.3": "E-mail non verificata",

  "verify-email-button-2.1": "Invia di nuovo e-mail",
  "verify-email-button-2.2": "Nuovo invio in corso...",
  "verify-email-button-2.3": "E-mail inviata",

  "verify-email-verification-error-message": "Il tuo indirizzo e-mail non è stato ancora verificato.",

  "verify-email-toast": "Abbi pazienza, potrebbero volerci alcuni minuti prima che l'e-mail venga visualizzata nella tua casella di posta.",

  "change-email-title": "Modifica e-mail",
  "change-email-text": "Inserisci il tuo indirizzo e-mail in modo da poterti inviare un'e-mail di verifica.",
  "change-email-placeholder": "Nuova e-mail",
  "change-email-button-1.1": "Modifica e-mail",
  "change-email-button-1.2": "Modifica in corso...",

  "reset-password-title": "Reimposta password",
  "reset-password-text": "Inserisci il tuo indirizzo e-mail e ti invieremo le istruzioni per reimpostare la password.",
  "reset-password-placeholder": "Indirizzo e-mail",
  "reset-password-button-text": "Continua",
  "reset-password-success": "Controlla l'indirizzo e-mail {{email}} per istruzioni su come reimpostare la password.",
  "reset-password-retry": "Invia nuovamente e-mail"
}
