import ejs from 'ejs';

/* eslint-disable import/no-unresolved */
import root from 'bundle-text:../templates/root.html';
import verifyEmail from 'bundle-text:../templates/verify-email.html';
import changeEmail from 'bundle-text:../templates/change-email.html';
import errorAuth0 from 'bundle-text:../templates/error-auth0.html';
import successGoToApp from 'bundle-text:../templates/success-go-to-app.html';
import check from 'bundle-text:../templates/check.html';
import loader from 'bundle-text:../templates/loader.html';
import buttonPrimary from 'bundle-text:../templates/button-primary.html';
import buttonSecondary from 'bundle-text:../templates/button-secondary.html';
import footer from 'bundle-text:../templates/footer.html';
import button from 'bundle-text:../templates/button.html';
import info from 'bundle-text:../templates/info.html';
import passwordReset from 'bundle-text:../templates/password-reset.html';

import { BUTTON_STATES } from './constants';
import t from './translate';

const TEMPLATES = {
  root,
  'verify-email': verifyEmail,
  'change-email': changeEmail,
  'password-reset': passwordReset,
  'error-auth0': errorAuth0,
  'success-go-to-app': successGoToApp,
  loader,
  check,
  footer,
  'button-primary': buttonPrimary,
  'button-secondary': buttonSecondary,
  button,
  info,
};

const temp = (template) => (args = {}) => {
  return ejs.compile(
    TEMPLATES[template],
    { client: true },
  )({
    ...args,
    t,
    BUTTON_STATES,
  }, null, (path, d) => temp(path)(d));
};

export default temp;

const ROOT = document.querySelector('.root');

export const go = (route, payload = {}) => {
  const el = document.createElement('div');
  el.setAttribute('data-controller', route);
  Object.entries(payload)
    .forEach(([key, value]) => el.setAttribute(`data-${route}-${key}-value`, value));

  ROOT.innerHTML = '';
  ROOT.append(el);
};
