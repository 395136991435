{
  "success-page-sub-logo-text": "Одна учетная запись {{company_name}} для ваших приложений.",
  "success-page-text": "Вернуться в приложение, чтобы продолжить настройку.",

  "return-to-app-button-text": "Назад в {{app}}",

  "error-page-title": "Ошибка!",
  "error-page-text": "При подключении к вашей учетной записи возникла проблема.",
  "error-page-two-factor-code-expired-error": "Истек срок действия кода двухфакторной аутентификации",
  "error-page-need-help-text": "Нужна помощь? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Перейдите на наш сайт справки</a>",
  "error-page-try-again-button": "Повторите попытку",
  "error-page-blocked-user-error": "В целях защиты ваших данных доступ к вашей учетной записи отключен. Чтобы восстановить доступ к своей учетной записи, вам необходимо сбросить пароль. Рекомендуем выбрать надежный и уникальный пароль, который вы никогда ранее не использовали.",
  "error-page-blocked-user-error2": "Доступ к вашей учетной записи отключен.",
  "error-user-exists": "Этот адрес электронной почты уже используется",

  "back": "Назад",

  "inline-error": "Что-то пошло не так. Повторите попытку.",

  "footer-1": "Одна учетная запись {{company_name}} для ваших приложений.",
  "footer-2": "© {{company_name}} Corporation или ее аффилированные лица, 2022 г. Все права сохранены.",

  "verify-email-title": "Подтвердите свой адрес электронной почты",

  "verify-email-text-1": "Учетная запись создана. Для повышения защиты своей учетной записи {{company_name}} вам необходимо проверить свой адрес электронной почты. Проверьте наличие письма, отправленного от {{company_name}} на адрес <b>{{email}}</b>. Следуйте инструкциям в электронном письме, затем вернитесь сюда, чтобы нажать кнопку <b>Проверить статус подтверждения</b>.",
  "verify-email-text-2": "При необходимости вы можете <b><a href=\"#\" data-action=\"verify-email#changeEmail\">изменить адрес электронной почты</a></b>, который вы используете для учетной записи {{company_name}}.",

  "verify-email-button-1.1": "Проверить статус подтверждения",
  "verify-email-button-1.2": "Проверка...",
  "verify-email-button-1.3": "Адрес электронной почты не подтвержден",

  "verify-email-button-2.1": "Отправить сообщение электронной почты повторно",
  "verify-email-button-2.2": "Повторная отправка...",
  "verify-email-button-2.3": "Сообщение отправлено",

  "verify-email-verification-error-message": "Ваш адрес электронной почты еще не подтвержден.",

  "verify-email-toast": "Не торопитесь, может пройти несколько минут, прежде чем электронное письмо появится в папке входящих сообщений.",

  "change-email-title": "Изменить адрес электронной почты",
  "change-email-text": "Введите свой адрес электронной почты, чтобы мы могли отправить вам электронное письмо для подтверждения.",
  "change-email-placeholder": "Новый адрес электронной почты",
  "change-email-button-1.1": "Изменить адрес электронной почты",
  "change-email-button-1.2": "Изменение...",

  "reset-password-title": "Изменить пароль",
  "reset-password-text": "Укажите адрес электронной почты, <br/> чтобы получить инструкции по смене пароля.",
  "reset-password-placeholder": "Адрес электронной почты",
  "reset-password-button-text": "Продолжить",
  "reset-password-success": "Мы отправили инструкции по сбросу пароля <br/> на следующий адрес электронной почты: {{email}}. <br/> Убедитесь, что вам пришло письмо.",
  "reset-password-retry": "Повторно отправить письмо на электронную почту"
}
