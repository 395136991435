{
  "success-page-sub-logo-text": "Uma Conta {{company_name}} para os seus aplicativos.",
  "success-page-text": "Voltar para o aplicativo para continuar a configuração.",

  "return-to-app-button-text": "Voltar para {{app}}",

  "error-page-title": "Epa!",
  "error-page-text": "Nós encontramos um problema ao conectar com a sua conta.",
  "error-page-two-factor-code-expired-error": "Código de autenticação de dois fatores expirado",
  "error-page-need-help-text": "Precisa de ajuda? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Visite nosso site de ajuda on-line</a>",
  "error-page-try-again-button": "Tente novamente",
  "error-page-blocked-user-error": "Para proteger seus dados, o acesso à sua conta foi desativado. Para obter acesso novamente à sua conta, você precisa redefinir a sua senha. Nós sugerimos que você selecione uma senha exclusiva forte que você nunca tenha usado antes.",
  "error-page-blocked-user-error2": "O acesso à sua conta foi desativado.",
  "error-user-exists": "Este endereço de e-mail já está sendo usado",

  "back": "Voltar",

  "inline-error": "Algo deu errado. Tente novamente.",

  "footer-1": "Uma Conta {{company_name}} para os seus aplicativos.",
  "footer-2": "© 2022 {{company_name}} Corporation ou suas afiliadas. Todos os direitos reservados.",

  "verify-email-title": "Verificar o seu e-mail",

  "verify-email-text-1": "Sua conta está criada. Para melhor proteger a sua conta {{company_name}}, precisamos confirmar o seu endereço de e-mail. Verifique um e-mail enviado por {{company_name}} para <b>{{email}}</b>. Siga as instruções no e-mail e depois volte aqui para clicar em <b>Verificar status da confirmação</b>.",
  "verify-email-text-2": "Se for necessário, você pode <b><a href=\"#\" data-action=\"verify-email#changeEmail\">alterar o endereço de e-mail</a></b> que usa para a sua conta {{company_name}}.",

  "verify-email-button-1.1": "Verificar status da confirmação",
  "verify-email-button-1.2": "Confirmando...",
  "verify-email-button-1.3": "E-mail não confirmado",

  "verify-email-button-2.1": "Reenviar e-mail",
  "verify-email-button-2.2": "Reenviando...",
  "verify-email-button-2.3": "E-mail enviado",

  "verify-email-verification-error-message": "Seu endereço de e-mail ainda não está confirmado.",

  "verify-email-toast": "Tenha paciência, pode levar alguns minutos para que o e-mail apareça na sua caixa de entrada.",

  "change-email-title": "Alterar e-mail",
  "change-email-text": "Informe seu endereço de e-mail para que possamos enviar a você um e-mail para confirmar.",
  "change-email-placeholder": "Novo e-mail",
  "change-email-button-1.1": "Alterar e-mail",
  "change-email-button-1.2": "Alterando...",

  "reset-password-title": "Redefinir senha",
  "reset-password-text": "Insira seu endereço de e-mail e lhe enviaremos <br/> instruções para redefinir sua senha.",
  "reset-password-placeholder": "Endereço de e-mail",
  "reset-password-button-text": "Continuar",
  "reset-password-success": "Verifique o endereço de e-mail {{email}} <br/> para ver instruções de como redefinir sua senha.",
  "reset-password-retry": "Reenviar e-mail"
}
