import Cookies from 'js-cookie';

import Controller from '../base';
import { authorize } from '../common';
import { state, isCallbackWhitelisted, isPasswordResetPath } from '../state';
import { getTokenByCode, getUserinfo } from '../apis/auth0';
import { AUTH0_CALLBACK_URLS_WHITELIST_ENABLED, EMAIL_VERIFICATION_FLOW_ENABLED, COOKIES_BASED_SKIP_VERIFICATION } from '../features';

export default class extends Controller {
  connect() {
    this.render();

    if (isPasswordResetPath()) {
      return this.APP.go(this.APP.ROUTES.PASSWORD_RESET);
    }

    // return early if redirect_uri is not in the list of "Allowed Callback URLs"
    // in auth0 management panel.
    if (
      AUTH0_CALLBACK_URLS_WHITELIST_ENABLED
      && !isCallbackWhitelisted()
    ) {
      return this.APP.go(this.APP.ROUTES.ERROR_AUTH0);
    }

    // return early if error in query params
    if (state.get('error')) {
      return this.APP.go(this.APP.ROUTES.ERROR_AUTH0);
    }

    // return early if email verification feature is disabled
    if (!EMAIL_VERIFICATION_FLOW_ENABLED) {
      return this.APP.go(this.APP.ROUTES.SUCCESS_GO_TO_APP);
    }

    const cookie = Cookies.get('skipVerification');
    // return early if no verification needed
    if (
      // query-params based skip
      (state.get('skipVerification') && !COOKIES_BASED_SKIP_VERIFICATION)

      // cookies-based skip
      || (state.get('state') === cookie && COOKIES_BASED_SKIP_VERIFICATION)
    ) {
      return this.APP.go(this.APP.ROUTES.SUCCESS_GO_TO_APP);
    }

    // return early for old client apps that do not provide the clientId
    if (!state.get('clientId')) {
      return this.APP.go(this.APP.ROUTES.SUCCESS_GO_TO_APP);
    }

    // get token + userinfo otherwise
    return getTokenByCode({
      code: state.get('code'),
      clientId: state.get('clientId'),
    }).then((r) => {
      if (r._status !== 200) throw new Error('INVALID_CODE');
      state.set('access_token', r.access_token);

      // check userinfo
      // this.APP.go(this.APP.ROUTES.CHECK_USER);
    }).then(() => {
      return getUserinfo({
        accessToken: state.get('access_token'),
      }).then((resp) => {
        if (resp._status !== 200) throw new Error(resp._status);

        // set udpated user info
        state.set('user', resp);

        const { email_verified } = resp;

        // check email verified.
        // if it is - push the user back to /authorize to re-authorize
        if (email_verified) {
          return authorize({ skipVerification: true });
        }

        // go to verify email otherwise
        return this.APP.go(this.APP.ROUTES.VERIFY_EMAIL);
      });
    }).catch(() => {
      this.APP.go(this.APP.ROUTES.ERROR_AUTH0);
    });
  }
}
