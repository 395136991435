import { Application } from '@hotwired/stimulus';
import { go } from './template';
import root from './controllers/root';
import errorAuth0 from './controllers/error-auth0';
import successGoToApp from './controllers/success-go-to-app';
import verifyEmail from './controllers/verify-email';
import changeEmail from './controllers/change-email';
import footer from './controllers/footer';
import passwordReset from './controllers/password-reset';

const CONTROLLERS = {
  'root': root,
  'error-auth0': errorAuth0,
  'success-go-to-app': successGoToApp,
  'verify-email': verifyEmail,
  'change-email': changeEmail,
  'footer': footer,
  'password-reset': passwordReset,
};

const ROUTES = Object.keys(CONTROLLERS)
  .reduce((acc, t) => {
    acc[t.replace(/-/g, '_').toUpperCase()] = t;
    return acc;
  }, {});

const stimulus = Application.start();
Object.entries(ROUTES).forEach(([, v]) => {
  const Controller = CONTROLLERS[v];
  Controller.TEMPLATE = v;
  Controller.ROUTES = ROUTES;
  stimulus.register(v, Controller);
});

go(ROUTES.ROOT);
