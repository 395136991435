import { Controller } from '@hotwired/stimulus';
import template from '../template';

export default class extends Controller {
  connect() {
    const node = new DOMParser()
      .parseFromString(template('footer')(), 'text/html')
      .body
      .firstElementChild;
    this.element.replaceWith(node);
  }
}
