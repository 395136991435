import cs from './cs.json';
import da from './da.json';
import de from './de.json';
import el from './el.json';
import en from './en.json';
import es from './es.json';
import fi from './fi.json';
import fr from './fr.json';
import hu from './hu.json';
import it from './it.json';
import ja from './ja.json';
import ko from './ko.json';
import nb from './nb.json';
import nl from './nl.json';
import pl from './pl.json';
import pt_BR from './pt-BR.json';
import ru from './ru.json';
import sk from './sk.json';
import sv from './sv.json';
import tr from './tr.json';
import zh_CN from './zh-CN.json';
import zh_TW from './zh-TW.json';

export default {
  cs,
  da,
  de,
  el,
  en,
  es,
  fi,
  fr,
  hu,
  it,
  ja,
  ko,
  nb,
  nl,
  pl,
  'pt-BR': pt_BR,
  ru,
  sk,
  sv,
  tr,
  'zh-CN': zh_CN,
  'zh-TW': zh_TW,
};
