{
  "success-page-sub-logo-text": "Un seul compte {{company_name}} pour vos applications.",
  "success-page-text": "Revenir à l'application pour continuer la configuration.",

  "return-to-app-button-text": "Revenir à {{app}}",

  "error-page-title": "Oups.",
  "error-page-text": "Nous avons rencontré un problème lors de la connexion à votre compte.",
  "error-page-two-factor-code-expired-error": "Le code d'authentification à deux facteurs a expiré",
  "error-page-need-help-text": "Besoin d'aide ? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Consultez notre site d'aide en ligne</a>",
  "error-page-try-again-button": "Veuillez réessayer",
  "error-page-blocked-user-error": "Pour protéger vos données, l'accès à votre compte a été désactivé. Pour récupérer l'accès à votre compte, vous devez réinitialiser votre mot de passe. Nous vous recommandons de choisir un mot de passe fort et unique que vous n'avez jamais utilisé.",
  "error-page-blocked-user-error2": "L'accès à votre compte a été désactivé.",
  "error-user-exists": "Cette adresse e-mail est déjà utilisée",

  "back": "Retour",

  "inline-error": "Une erreur s'est produite. Veuillez réessayer.",

  "footer-1": "Un seul compte {{company_name}} pour vos applications.",
  "footer-2": "© 2022 {{company_name}} Corporation ou ses sociétés affiliées. Tous droits réservés.",

  "verify-email-title": "Vérifier votre e-mail",

  "verify-email-text-1": "Votre compte est créé. Afin de mieux protéger votre compte {{company_name}}, nous devons vérifier votre adresse e-mail. Un e-mail a été envoyé par {{company_name}} à <b>{{email}}</b>. Suivez les instructions de l'e-mail, puis revenez ici pour cliquer sur <b>Vérifier l'état de la vérification</b>.",
  "verify-email-text-2": "Si nécessaire, vous pouvez <b><a href=\"#\" data-action=\"verify-email#changeEmail\">modifier l'adresse e-mail</a></b> que vous utilisez pour votre compte {{company_name}}.",

  "verify-email-button-1.1": "Vérifier l'état de la vérification",
  "verify-email-button-1.2": "Vérification…",
  "verify-email-button-1.3": "E-mail non vérifié",

  "verify-email-button-2.1": "Envoyer l'e-mail à nouveau",
  "verify-email-button-2.2": "Renvoi…",
  "verify-email-button-2.3": "E-mail envoyé",

  "verify-email-verification-error-message": "Votre adresse e-mail n'est pas encore vérifiée.",

  "verify-email-toast": "Soyez patient, quelques minutes peuvent s'écouler avant que l'e-mail n'apparaisse dans votre boîte de réception.",

  "change-email-title": "Changer l'adresse e-mail",
  "change-email-text": "Entrez votre adresse e-mail afin que nous puissions vous envoyer un e-mail de vérification.",
  "change-email-placeholder": "Nouvel e-mail",
  "change-email-button-1.1": "Changer l'adresse e-mail",
  "change-email-button-1.2": "Modification…",

  "reset-password-title": "Réinitialiser le mot de passe",
  "reset-password-text": "Saisissez votre adresse e-mail et nous vous <br/> enverrons des instructions pour réinitialiser <br/> votre mot de passe.",
  "reset-password-placeholder": "Adresse e-mail",
  "reset-password-button-text": "Continuer",
  "reset-password-success": "Veuillez consulter l'adresse e-mail <br/> {{email}} pour savoir comment réinitialiser <br/> votre mot de passe.",
  "reset-password-retry": "Renvoyer un e-mail"
}
