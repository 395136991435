{
  "success-page-sub-logo-text": "앱에 대한 1개의 {{company_name}} 계정.",
  "success-page-text": "애플리케이션으로 돌아가 설정을 계속 진행하십시오.",

  "return-to-app-button-text": "{{app}} 앱으로 돌아가기",

  "error-page-title": "죄송합니다.",
  "error-page-text": "계정에 연결하는 동안 문제가 발생했습니다.",
  "error-page-two-factor-code-expired-error": "2단계 인증 코드가 만료되었습니다",
  "error-page-need-help-text": "도움이 필요하십니까? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">온라인 도움말 사이트 방문하기</a>",
  "error-page-try-again-button": "다시 시도하십시오",
  "error-page-blocked-user-error": "사용자의 데이터를 보호하기 위해 계정에 대한 액세스가 비활성화되었습니다. 계정에 다시 액세스하려면 암호를 재설정해야 합니다. 이전에 사용한 적이 없는 강력하고 고유한 암호를 선택하는 것이 좋습니다.",
  "error-page-blocked-user-error2": "계정에 대한 액세스가 비활성화되었습니다.",
  "error-user-exists": "이미 사용된 이메일 주소임",

  "back": "뒤로",

  "inline-error": "오류가 발생했습니다. 다시 시도하십시오.",

  "footer-1": "앱에 대한 1개의 {{company_name}} 계정.",
  "footer-2": "© 2022 {{company_name}} 또는 계열사 All rights reserved.",

  "verify-email-title": "이메일 확인",

  "verify-email-text-1": "계정을 생성했습니다. {{company_name}} 계정을 보다 안전하게 보호하려면 이메일 주소를 확인해야 합니다. {{company_name}}에서 <b>{{email}}</b>(으)로 전송된 이메일을 확인하십시오. 이메일에 있는 지침을 따른 다음에 여기로 돌아와 <b>확인 상태 확인</b>을 클릭하십시오.",
  "verify-email-text-2": "필요한 경우, {{company_name}} 계정에서 사용하는 <b><a href=\"#\" data-action=\"verify-email#changeEmail\">이메일 주소를 변경</a></b>할 수 있습니다.",

  "verify-email-button-1.1": "확인 상태 확인",
  "verify-email-button-1.2": "확인 중...",
  "verify-email-button-1.3": "이메일이 확인되지 않음",

  "verify-email-button-2.1": "이메일 재전송",
  "verify-email-button-2.2": "재전송 중...",
  "verify-email-button-2.3": "이메일이 전송됨",

  "verify-email-verification-error-message": "이메일 주소를 아직 확인하지 못했습니다.",

  "verify-email-toast": "이메일이 받은 편지함에 표시되는 데 몇 분이 걸릴 수 있습니다.",

  "change-email-title": "이메일 선택",
  "change-email-text": "확인할 이메일을 보낼 수 있도록 이메일 주소를 입력하십시오.",
  "change-email-placeholder": "새로운 이메일",
  "change-email-button-1.1": "이메일 선택",
  "change-email-button-1.2": "변경 중...",

  "reset-password-title": "암호 재설정",
  "reset-password-text": "이메일 주소를 입력하면 비밀번호 재설정 지침을 보내드립니다.",
  "reset-password-placeholder": "이메일 주소",
  "reset-password-button-text": "계속",
  "reset-password-success": "비밀번호 재설정 지침을 위해 {{email}} 이메일 주소를 확인하세요.",
  "reset-password-retry": "이메일 재전송"
}
