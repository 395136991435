import { customFetch } from './wrapper';

const base = 'service.auth.url';

export const updateUserEmail = ({ userId, email, token }) => {
  const path = `/authservice/v2/auth0/users/${encodeURIComponent(userId)}?avoidTokenInvalidation=true`;

  return customFetch(base, path, {
    method: 'PUT',
    headers: { 'Authorization': `Bearer ${token}` },
    body: JSON.stringify({ email }),
  });
};

export const requestEmailVerification = ({ userId, token }) => {
  const path = `/authservice/v2/user/${encodeURIComponent(userId)}/email/verification`;

  return customFetch(base, path, {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${token}` },
  });
};
