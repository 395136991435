import { state } from './state';
import LOCALES from '../locales';

export const normalizeLocaleCode = (localeCode) => {
  // return early if no localeCode is set
  if (!localeCode) return false;

  const longCodeMatch = Object.keys(LOCALES)
    .find((l) => l === localeCode.replace('_', '-'));
  if (longCodeMatch) return longCodeMatch;

  return Object.keys(LOCALES)
    .find((l) => l.split('-')[0] === localeCode.replace('_', '-').split('-')[0]);
};

// 1. use `locale` if passed in query params
// 2. use browser-detected locale if detection succeeded
// 3. use 'en' as fallback
export const current = normalizeLocaleCode(state.get('ui_locales'))
  || normalizeLocaleCode(state.get('locale'))
  || normalizeLocaleCode(navigator.language || navigator.browserLanguage)
  || 'en';

export default LOCALES;
