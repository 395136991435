import { state } from '../state';

// data coming from env
const { CONFIG_URL } = process.env;

const getConfigItem = (key) => {
  // return if present
  if (state.get(key)) return Promise.resolve(state.get(key));

  // if it's missing and it's a cloud endpoint config key -
  // fetch config file, set to config and return
  if (key.match('service.')) {
    return fetch(CONFIG_URL)
      .then((r) => r.json())
      .then((r) => {
        // set the whole set of urls to config
        Object.entries(r.data.componentMap['cloud.service.urls']).forEach(([k, v]) => state.set(k, v));

        // return the value
        return state.get(key);
      });
  }

  // reject otherwise
  return Promise.reject(new Error(`Config key ${key} is missing`));
};

export const customFetch = (base, path, options) => getConfigItem(base)
  .then((baseUrl) => {
    const url = `${baseUrl}${path}`;

    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'content-type': 'application/json',
      },
    }).then((resp) => {
      const status = { _status: resp.status };
      if (options.ignoreResponseBody) return status;
      return resp.json().then((r) => ({ ...r, ...status }));
    });
  });
