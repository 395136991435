{
  "success-page-sub-logo-text": "Jeden účet {{company_name}} pro všechny vaše aplikace.",
  "success-page-text": "Vraťte se do aplikace a pokračujte v nastavení.",

  "return-to-app-button-text": "Zpět do aplikace {{app}}",

  "error-page-title": "Jejda!",
  "error-page-text": "Došlo k problému s připojením k vašemu účtu.",
  "error-page-two-factor-code-expired-error": "Vypršela platnost kódu pro dvojúrovňové ověřování",
  "error-page-need-help-text": "Potřebujete pomoc? <a href=\"https://support.wdc.com\" target=\"_blank\" rel=\"noopener noreferrer\">Navštivte náš web pomoci online</a>",
  "error-page-try-again-button": "Zkuste to znovu",
  "error-page-blocked-user-error": "V zájmu ochrany vašich dat byl přístup k vašemu účtu zakázán. Chcete-li znovu získat přístup ke svému účtu, musíte obnovit heslo. Doporučujeme zvolit silné a jedinečné heslo, které jste ještě nikdy nepoužili.",
  "error-page-blocked-user-error2": "Přístup k vašemu účtu byl zakázán.",
  "error-user-exists": "Tato e-mailová adresa je již používána",

  "back": "Zpět",

  "inline-error": "Něco se nezdařilo. Zkuste to znovu.",

  "footer-1": "Jeden účet {{company_name}} pro všechny vaše aplikace.",
  "footer-2": "© 2022 {{company_name}} Corporation nebo její pobočky. Všechna práva vyhrazena.",

  "verify-email-title": "Ověřte svou e-mailovou adresu",

  "verify-email-text-1": "Váš účet byl vytvořen. Z důvodu lepší ochrany vašeho účtu {{company_name}} musíte ověřit svou e-mailovou adresu. Zkontrolujte e-maily odeslané společností {{company_name}} na e-mailovou adresu <b>{{email}}</b>. Postupujte podle pokynů v e-mailu a poté se vraťte sem a klikněte na tlačítko <b>Zkontrolovat stav ověření</b>.",
  "verify-email-text-2": "V případě potřeby můžete <b><a href=\"#\" data-action=\"verify-email#changeEmail\">změnit e-mailovou adresu</a></b>, kterou používáte pro svůj účet {{company_name}}.",

  "verify-email-button-1.1": "Zkontrolovat stav ověření",
  "verify-email-button-1.2": "Ověřuje se...",
  "verify-email-button-1.3": "E-mail nebyl ověřen",

  "verify-email-button-2.1": "Odeslat e-mail znovu",
  "verify-email-button-2.2": "Odesílá se znovu...",
  "verify-email-button-2.3": "E-mail byl odeslán",

  "verify-email-verification-error-message": "Vaše e-mailová adresa ještě není ověřena.",

  "verify-email-toast": "Počkejte, než se e-mail objeví ve vaší schránce. Může to trvat několik minut.",

  "change-email-title": "Změnit e-mail",
  "change-email-text": "Zadejte e-mailovou adresu, na kterou vám pošleme ověřovací e-mail.",
  "change-email-placeholder": "Nový e-mail",
  "change-email-button-1.1": "Změnit e-mail",
  "change-email-button-1.2": "Mění se...",

  "reset-password-title": "Obnovit heslo",
  "reset-password-text": "Zadejte svou e-mailovou adresu a my <br/> vám zašleme pokyny pro obnovu hesla.",
  "reset-password-placeholder": "E-mailová adresa",
  "reset-password-button-text": "Pokračovat",
  "reset-password-success": "Zkontrolujte prosím adresu {{email}}, <br/> zaslali jsme na ni instrukce pro restart hesla.",
  "reset-password-retry": "Odeslat e-mail znovu"
}
